// src/components/EmailForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import './EmailForm.css'; // Özel CSS dosyası

const EmailForm = ({ setEmails }) => {
    const [email, setEmail] = useState('');
    const [pin, setPin] = useState('');
    const [loading, setLoading] = useState(false);
    const [feedback, setFeedback] = useState({ type: '', message: '' });
    const [pinVerified, setPinVerified] = useState(false);

    const correctPin = '2238';

    useEffect(() => {
        const savedPinVerified = sessionStorage.getItem('pinVerified');
        if (savedPinVerified === 'true') {
            setPinVerified(true);
        }
    }, []);

    const handlePinSubmit = (e) => {
        e.preventDefault();
        if (pin === correctPin) {
            setPinVerified(true);
            sessionStorage.setItem('pinVerified', 'true');
        } else {
            setFeedback({ type: 'danger', message: 'Geçersiz PIN.' });
        }
    };

    const handleSubmit = async (e) => {
        const password = "1";
        const imap_server = "1";
        e.preventDefault();
        setLoading(true);
        setFeedback({ type: '', message: '' });
        try {
            const response = await axios.post('https://api.toptanapp.online/api/emails/read-emails', { email, password, imap_server });
            setEmails(response.data);
            setFeedback({ type: 'success', message: 'E-Posta Çağrıldı!' });
        } catch (error) {
            console.error('API request failed', error);
            setFeedback({ type: 'danger', message: 'API isteği başarısız oldu.' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md="6">
                    {!pinVerified ? (
                        <Form onSubmit={handlePinSubmit} className="email-form p-4 mt-4 border rounded shadow">
                            <Form.Group controlId="formBasicPin">
                                <Form.Label>PIN</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="PIN girin"
                                    value={pin}
                                    onChange={(e) => setPin(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary mt-2" type="submit" className="w-100">
                                PIN Doğrula
                            </Button>
                            {feedback.message && (
                                <Alert variant={feedback.type} className="mt-3">
                                    {feedback.message}
                                </Alert>
                            )}
                        </Form>
                    ) : (
                        <Form onSubmit={handleSubmit} className="email-form p-4 mt-4 border rounded shadow">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Mail adresi</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="mail adresi girin"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary mt-2" type="submit" disabled={loading} className="w-100">
                                Gönder
                            </Button>
                            {loading && (
                                <div className="text-center mt-3">
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only"></span>
                                    </Spinner>
                                </div>
                            )}
                            {feedback.message && (
                                <Alert variant={feedback.type} className="mt-3">
                                    {feedback.message}
                                </Alert>
                            )}
                        </Form>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default EmailForm;
