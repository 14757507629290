// src/App.js
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import EmailForm from './components/EmailForm';
import EmailList from './components/EmailList';

function App() {
    const [emails, setEmails] = useState([]);

    return (
        <Container>
            <h1 className="text-center mt-4">Hızlı Mail E-Posta</h1>
            <EmailForm setEmails={setEmails} />
            <EmailList emails={emails} />
        </Container>
    );
}

export default App;
