// src/components/EmailList.js
import React from 'react';
import { Container, ListGroup, Card } from 'react-bootstrap';

const EmailList = ({ emails }) => {
    return (
        <Container className="mt-4">
            <ListGroup>
                {emails.map((email, index) => (
                    <ListGroup.Item key={index} className="mb-3">
                        <Card>
                            <Card.Body>
                                <Card.Title>{email.subject}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">From: {email.from}</Card.Subtitle>
                                <Card.Text><strong>Date:</strong> {email.date}</Card.Text>
                                <div dangerouslySetInnerHTML={{ __html: email.body }} />
                            </Card.Body>
                        </Card>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Container>
    );
};

export default EmailList;
